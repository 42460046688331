import React from 'react'
import tw, { css } from 'twin.macro'
import { colors, breakpoints } from '../../../config/design-system'
import { tagStyles, h1Styles, mediaQuery, btnStyles as btnFontStyles, toRem } from '../../styles'
import { buttonStyles } from '../Button'
import { useVideoForViewport } from '../../utils/hooks'
import heroVideoSm from '../../assets/careers/hero-mobile.mp4'
import heroVideoLg from '../../assets/careers/hero-desktop.mp4'

const innerSectionStyles = css`
  ${tw`grid md:h-screen relative md:grid-cols-12 mb-32 md:mb-0`};
  @media (min-width: ${breakpoints['tablet-landscape']}px) {
    &::after {
      content: '';
      ${tw`w-full absolute bottom-0`};
      height: 25%;
      background: linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 100) 100%);
    }
  }
`

const videoSectionStyles = css`
  ${tw`row-span-5 md:h-screen md:col-start-1 md:col-end-6 md:row-span-6`};
`

const videoStyles = css`
  ${tw`max-h-screen md:w-full md:h-full object-cover`}
  width: 80%;
`

const textSectionStyles = css`
  ${tw`px-10 md:px-0 md:col-start-6 md:col-end-12 md:row-span-6 md:flex md:flex-col md:justify-center md:w-full md:h-full md:ml-10`};
  position: relative;
  bottom: 24%;
  ${mediaQuery(`
    position: inherit;
    min-width: ${toRem(320)};
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  `)}
`

const linkStyles = css`
  &:hover {
    span:last-child {
      background-color: ${colors['light-bg-color']};
      color: ${colors['dark-text-color']};
    }
  }
`

const underlineStyles = css`
  ${tw`absolute`};
  width: 105%;
  left: 0;
  top: 0;
  transform: translate(-1%, -2%);
`

const Hero = () => {
  const videoSrc = useVideoForViewport(heroVideoSm, heroVideoLg)

  return (
    <section css={innerSectionStyles}>
      <div css={videoSectionStyles}>
        <video src={videoSrc} type="video/mp4" css={videoStyles} autoPlay loop muted playsInline />
      </div>
      <div css={textSectionStyles} tw="font-subheading">
        <span css={tagStyles}>Ready to satisfy your passion?</span>
        <p css={h1Styles} tw="mt-2 leading-tight font-thin">
          Join the <strong tw="font-medium inline-block mr-2 md:mr-0"> most </strong>
          <strong tw="font-medium relative md:mx-3">
            talented{' '}
            <svg
              css={underlineStyles}
              fill="none"
              viewBox="0 0 183 35"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M170.818 14.085C140.329-3.51 19.135-3.209 1.663 16.691c-11.849 16.734 82.149 22.521 156.514 10.274C232.543 14.717 116.936-4.37 40.934 6.353"
                stroke="#E55708"
              />
            </svg>
          </strong>
          <strong tw="font-medium">team </strong>
          in the creative business
        </p>
        <div>
          <a
            href="#careerRoll"
            css={[btnFontStyles, linkStyles]}
            tw="flex items-center gap-3 mt-4 font-primary"
            title="Skip to Open Positions"
          >
            <span tw="uppercase">Open positions</span>
            <span css={buttonStyles}>↓</span>
          </a>
        </div>
      </div>
    </section>
  )
}

export { Hero }
