import React, { useState } from 'react'
import 'twin.macro'
import Layout from '../components/Layout'
import Header from '../components/Header'
import Menu from '../components/Menu'
import Footer from '../components/Footer'
import { Hero, CareerRoll } from '../components/careers'

const Careers = ({ location, data }) => {
  const [showFullMenu, setShowFullMenu] = useState(false)

  const handleMenuAction = () => {
    setShowFullMenu((menuState) => !menuState)
  }

  return (
    <Layout title="Careers" description="Join the most talented team in the creative business.">
      <Header menuAction={handleMenuAction} />
      <main tw="relative">
        <Hero />
        <CareerRoll />
        <Menu show={showFullMenu} closeMenu={handleMenuAction} location={location} />
      </main>
      <Footer />
    </Layout>
  )
}

export default Careers
