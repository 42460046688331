import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import tw, { css } from 'twin.macro'
import { h1Styles, tagStyles, toRem, mediaQuery } from '../../styles'
import { buttonStyles } from '../Button'
import { breakpoints, colors } from '../../../config/design-system'

const GET_CAREERS = graphql`
  query CareerRollQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "career" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            joblocation
            category
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`

const sectionStyles = css`
  ${tw`flex flex-col mb-20 md:grid md:grid-cols-12 md:mb-0`};
  padding: 0 1.25rem;
  ${mediaQuery(`
    padding: 8rem 1.25rem 10rem;
    margin-bottom: inherit;
  `)}
`

const underlineStyles = css`
  ${tw`absolute`};
  width: 105%;
  left: 0;
  bottom: 0;
  transform: translate(-1%, 80%);
`

const asideTitleStyles = css`
  @media (min-width: ${breakpoints['tablet-landscape']}px) {
    max-width: ${toRem(425)};
  }
`

const careersContainer = css`
  ${tw`md:col-start-6 md:col-end-13 md:pl-8`};
  max-width: ${toRem(675)};
`

const careerItemStyles = css`
  ${tw`flex items-center justify-between py-4 relative`};
  &::after {
    ${tw`flex w-full absolute bottom-0`}
    content: '';
    height: ${toRem(1)};
    background-color: ${colors['light-bg-color']};
    opacity: 0.25;
  }
`

const careerItemLocationStyles = css`
  &::after {
    ${tw`px-2`};
    content: '·';
  }
`

const CareerRoll = () => {
  const data = useStaticQuery(GET_CAREERS)
  const { edges: careers } = data?.allMarkdownRemark || {}

  return (
    <section id="careerRoll" css={sectionStyles}>
      <aside tw="md:col-start-1 md:col-end-5 mb-20">
        <strong css={[h1Styles, asideTitleStyles]}>
          Find the perfect position for
          <span tw="relative ml-2">
            you
            <svg
              viewBox="0 0 69 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              css={underlineStyles}
            >
              <path
                d="M1.067 6.594C21.857 2.429 32.293 1.517 44.48 1.34c13.39-.194 18.916 2.37 23.516 4.02"
                stroke="#E55708"
              />
            </svg>
          </span>
        </strong>
      </aside>
      <div css={careersContainer}>
        <small css={tagStyles} tw="flex font-thin mb-4 md:mt-3">
          Open Positions: {careers.length}
        </small>
        {careers?.length > 0 ? (
          <ul>
            {careers?.map(({ node: data }) => {
              const {
                id,
                fields: { slug } = {},
                frontmatter: { title, joblocation, category } = {}
              } = data
              return (
                <li key={id} css={careerItemStyles}>
                  <div>
                    <strong tw="font-medium text-paragraph-lg md:font-normal text-header5-lg">
                      {title}
                    </strong>
                    <div tw="font-thin text-paragraph-sm pt-2">
                      <span css={careerItemLocationStyles}>{joblocation}</span>
                      <span>{category}</span>
                    </div>
                  </div>
                  <Link to={slug}>
                    <span css={buttonStyles}>→</span>
                  </Link>
                </li>
              )
            })}
          </ul>
        ) : (
          <p tw="text-paragraph-lg">No open positions at this time.</p>
        )}
      </div>
    </section>
  )
}

export { CareerRoll }
